export const EN = {
  title: 'GallerYeet',
  subtitle: 'a place where you can find photos and videos from my travels',
  description:
    "Welcome to our travel and photography blog! Explore travel adventures through individual galleries, posts and a special Instax section. Join the world travels I'm sharing here.",
  home: 'Home',
  profile: 'Profile',
  view: 'View',
  back: 'Back',
  gallery: {
    title: 'Gallery',
    noGalleries: 'No galleries were found.',
    homepageLastGalleries: 'Newest galleries',
    homepageLastInstax: 'Newest Instax photos',
  },
  posts: {
    title: 'Posts',
    noPosts: 'No posts were found.',
    homepageLastPosts: 'Newest posts',
    published: 'Published: ',
  },
  instax: {
    title: 'Instax',
  },
  admin: {
    title: 'Admin',
    create: 'Create',
    createGallery: 'Create gallery',
    createPost: 'Create post',
    galleries: 'Galleries',
    add: 'Add',
    delete: 'Delete',
    deleteGallery: 'Are you sure you want to delete the gallery?',
    deletePost: 'Are you sure you want to delete the post?',
    deleteGalleryText: 'Deleting the gallery is irreversible.',
    inputTitle: 'Title',
    photo: 'Photo',
    description: 'Description',
    thumbnail: 'Thumbnail',
    contents: 'Contents',
    content: 'Content',
    edit: 'Edit',
  },
  footer: {
    title: 'GallerYeet',
    subtitle: 'Created by GTomy, 2024',
    projects: 'Projects',
    usefulLinks: 'Useful links',
  },
};
